import React from "react"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import About from "../components/sections/About"
import CTA from "../components/sections/CTA"
import Recommendation from "../components/sections/Recommendation"
import Blog from "../components/sections/Blog"

const IndexPage = () => (
  <Layout homepage>
    <SEO title="Extrém sportbemutatók világszínvonalon" />
    <About />
    <Blog />
    <CTA />
    <Recommendation />
  </Layout>
)

export default IndexPage
