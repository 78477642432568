import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Container from "../Container"
import Button from "../Button"
import { useAboutAssets } from "../../hooks/about"

const hashtags = [
  "#egyhelyről",
  "#egyszerződéssel",
  "#megbízhatópartnertől",
  "#szuperlátványosprodukciók",
  "#hosszútávű_együttműködés",
  "#rendezvények_összevonása",
  "#kedvezmények_maximalizálása",
  "#évente_megújuló_paletta",
]

const Section = styled.section`
  position: relative;
  padding-bottom: 2.2rem;
`
const Video = styled.iframe`
  width: 100%;
  display: block;
  max-width: 780px;
  margin: 0 auto 50px auto;
  border: none;
  border: 2px solid #fff;

  @media all and (min-width: 768px) {
    height: 335px;
    margin: 0 auto 100px auto;
  }
`
const GridWithCards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;

  @media all and (min-width: 768px) {
    grid-template-columns: 6fr 5fr;
  }
`
const BlockTitle = styled.h2`
  font-size: 2.222223em;
  margin-bottom: 0.5rem;
`
const Tag = styled.span`
  color: #fff;
  text-transform: uppercase;
  background-color: #1b2840;
  padding: 5px 15px 0 15px;
  display: inline-block;
  letter-spacing: 2px;
  font-family: "Teko", sans-serif;
  line-height: 0.9;
  margin-bottom: 1rem;
`
const Accent = styled.span`
  color: #6c9fff;
`
const BoldAccent = styled(Accent)`
  font-weight: 700;
`
const Content = styled.p``
const Column = styled.div`
  align-self: center;

  ${Content}:last-of-type {
    margin-bottom: 2.77777778rem;
  }
`
const CardGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  @media all and (min-width: 769px) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
`
const Card = styled.div`
  position: relative;
  padding: 20px;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%
  );
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  .gatsby-image-wrapper {
    margin: 0 auto 2rem auto;
    max-width: 150px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    img {
      margin-bottom: 0;
    }
  }

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 2px,
      2px 2px,
      2px calc(100% - 2px),
      calc(100% - 11px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 11px),
      calc(100% - 2px) 2px,
      0 2px
    );
    background: #fff;
    z-index: -1;
    opacity: 1;
  }

  h3 {
    font-size: 1em;
    text-align: center;
    margin: 0;
  }

  @media all and (min-width: 768px) {
    grid-column: 2;
    grid-row: 2 / 4;

    &:first-child {
      grid-column: 1;
      grid-row: 1 / 3;
    }

    &:last-child {
      grid-column: 1;
      grid-row: 3 / 5;
    }
  }
`
const GridWithNegativeImage = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  margin-bottom: 50px;

  @media all and (min-width: 769px) {
    grid-template-columns: 5fr 7fr;
  }

  .gatsby-image-wrapper {
    img {
      margin-bottom: 0;
    }

    @media all and (min-width: 769px) {
      margin-top: -100px;
    }
  }

  & > ${Column} {
    &:last-of-type {
      margin-bottom: 0;
    }

    @media all and (min-width: 769px) {
      padding-top: 100px;
    }
  }
`
// const Callout = styled.p`
//   font-size: 1.6666667em;
//   font-weight: 100;
//   font-style: italic;
// `
const HashtagCloud = styled.div`
  text-align: left;
`
const Hashtag = styled.span`
  color: #5177bf;
  padding-right: 5px;
  font-size: 0.888888889em;
  display: inline-block;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`
const HandContainer = styled.div`
  display: none;
  @media all and (min-width: 1681px) {
    display: block;
    width: 350px;
    position: absolute;
    bottom: 130px;
    right: 0;
  }
`
const Strong = styled.span`
  font-weight: 700;
`

const About = () => {
  const {
    reality,
    professional,
    international,
    dave,
    bg,
    hand,
  } = useAboutAssets()

  return (
    <Section id="rolunk" bg={bg}>
      <Container>
        <Video
          src="https://www.youtube-nocookie.com/embed/kz3ZyUM_a2A"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
          className="wow animate__fadeIn"
        />
        <GridWithCards>
          <Column>
            <BlockTitle className="wow fadeInLeft">
              <Accent>Extrém sportbemutatók és showműsorok</Accent> teljes
              palettája
            </BlockTitle>
            <Tag className="wow fadeInLeft">Flipunit production</Tag>
            <Content className="wow fadeInLeft">
              Vannak csapatok, akik rendezvényeken bemutatóznak.
            </Content>
            <Content className="wow fadeInLeft" data-wow-delay=".2s">
              És vagyunk mi, a FLIPUNIT PRODUCTION, akik teljes szívünket
              beleadjuk, amikor fellépésről van szó. A{" "}
              <Strong>Te rendezvényed</Strong> csúcspontja, a mi szenvedélyünk.
              Tervezzünk közösen olyan műsort, ami garantálja minden vendégének
              a <BoldAccent>WOW élményt</BoldAccent> legyen szó nemzetközi
              sporteseményről, városi vagy céges rendezvényről.
            </Content>
            <Button
              to="/sportbemutatok"
              className="wow fadeIn"
              data-wow-delay=".4s"
            >
              Extrém sportbemutatóink, showműsoraink
            </Button>
          </Column>
          <CardGrid>
            <Card className="wow fadeInRight">
              <Img fluid={international} alt="flip-unit-nemzetközi" />
              <h3>Nemzetközi ügynökség vagyunk</h3>
            </Card>
            <Card className="wow fadeInRight" data-wow-delay=".2s">
              <Img fluid={professional} alt="flip-unit-profizmus" />
              <h3>Valóban professzionális fellépőkkel dolgozunk</h3>
            </Card>
            <Card className="wow fadeInRight" data-wow-delay=".4s">
              <Img fluid={reality} alt="flip-unit-valóság" />
              <h3>A valóság megegyezik a weboldalunkon látható tartalmakkal</h3>
            </Card>
          </CardGrid>
        </GridWithCards>
        <GridWithNegativeImage>
          <Img
            fluid={dave}
            className="wow fadeIn"
            alt="flip-unit-balogh-dávid"
          />
          <Column>
            <BlockTitle className="wow fadeIn">
              <Accent>szakmai színvonal ÉS SZERVEZÉS</Accent>
              <br />
              Balogh Dávid vezetésével
            </BlockTitle>
            <Content className="wow fadeIn" data-wow-delay=".2s">
              Saját hitvallása szerint a sportbemutatók során nyújtott
              teljesítményen felül kiemelten számít a megbízhatóság, és az, hogy
              akivel együtt dolgozik valóban csapatjátékos legyen és lehessen rá
              számítani.
            </Content>
            <HashtagCloud>
              {hashtags.map((hashtag, idx) => (
                <Hashtag
                  key={idx}
                  className="wow fadeIn"
                  data-wow-delay={`${(idx * 2) / 10}s`}
                >
                  {hashtag}
                </Hashtag>
              ))}
            </HashtagCloud>
          </Column>
        </GridWithNegativeImage>
      </Container>
      <HandContainer>
        <Img fluid={hand} alt="flip-unit-kézen-állás" />
      </HandContainer>
    </Section>
  )
}

export default About
