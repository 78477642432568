import React from "react"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"

const RecommendationGrid = styled.div`
  grid-template-columns: 1fr;
  margin-bottom: 2.7777778rem;
  min-width: 100%;
  padding: 0 15px;
  ${props =>
    props.position ? `transform: translateX(${props.position}%)` : ""};
  transition: transform 0.5s ease;
  will-change: transform;

  @media all and (min-width: 769px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`
const Images = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 5rem;
  display: flex;

  @media all and (min-width: 769px) {
    margin-bottom: 0;
    justify-content: flex-start;
  }
`
const Image = styled(Img)`
  max-width: 100%;
  width: 100%;
  height: calc(100vw / 2);
  background-color: #1a1a1a;
  position: relative;

  @media all and (min-width: 769px) {
    margin-bottom: 0;
    max-width: calc(100% - 62px);
    height: 280px;
  }
`
const Profile = styled(Img)`
  width: 144px;
  height: 144px;
  background-color: pink;
  border-radius: 50%;
  border: 10px solid #1a1a1a;
  position: absolute;
  bottom: -72px;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute !important;

  @media all and (min-width: 769px) {
    top: 50%;
    right: -10px;
    left: initial;
    bottom: initial;
    transform: translate(0, -50%);
  }
`
const Content = styled.div`
  align-self: center;

  a {
    color: #6c9fff;
  }

  a {
    text-decoration: none;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
  @media all and (min-width: 768px) {
  }
`
const ContentTitle = styled.h3`
  font-size: 1.5em;
  color: #6c9fff;
  margin-bottom: 0.4rem;
  line-height: 1.1;
`
const ContentAlt = styled.p``

const RecommendationCard = React.forwardRef(
  ({ name, title, body, cover, profile, position }, ref) => {
    return (
      <RecommendationGrid position={position} ref={ref}>
        <Images>
          <Image fluid={cover.src.fluid} alt={`${name} ajánlásával`}></Image>
          <Profile fluid={profile.src.fluid} alt={name} />
        </Images>
        <Content>
          <ContentTitle>{name}</ContentTitle>
          <ContentAlt>{title}</ContentAlt>
          <MDXRenderer>{body}</MDXRenderer>
        </Content>
      </RecommendationGrid>
    )
  }
)

export default RecommendationCard
