import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { useHeroAssets } from "../../hooks/hero"
import Button from "../Button"

const Section = styled.section`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.bg})`};
  height: ${props => (props.homepage ? "100vh" : "72vh")};
  display: flex;
  justify-content: center;
  align-items: ${props => (props.homepage ? "center" : "flex-end")};
  position: relative;
  ${props => !props.homepage && "padding-bottom: 2rem"};

  @media all and (min-width: 768px) {
    ${props => !props.homepage && "padding-bottom: 3.75rem"};
  }
`
const Container = styled.div`
  max-width: 630px;
  margin: 0 auto;
  text-align: center;

  @media all and (min-width: 1600px) {
    ${props => props.homepage && "padding-bottom: 8.3333333rem"}
  }
`
const HeroTitle = styled.h1`
  font-size: 2.5em;
  line-height: 0.9;
  color: #fff;
  margin: 0;
  letter-spacing: 0;

  @media all and (min-width: 768px) {
    font-size: 3.5em;
  }

  @media all and (min-width: 1025px) {
    font-size: 4em;
  }

  @media all and (min-width: 1281px) {
    font-size: ${props => (props.homepage ? "4.5em" : "4em")};
  }
`
const Accent = styled.span`
  color: #6c9fff;
`
const StyledButton = styled(Button)`
  font-size: 1.4em;
  padding: 15px 20px 10px 20px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 145px;
  left: 0;
  right: 0;
  display: none;

  @media all and (min-width: 768px) {
    font-size: 1.2em;
    bottom: 150px;
    display: block;
    max-width: 150px;
  }

  @media all and (min-width: 1281px) {
    font-size: 1.77777778em;
    bottom: 200px;
    display: block;
    max-width: 200px;
  }

  @media all and (min-width: 1600px) {
    bottom: 250px;
    display: block;
  }
`
const Triangle = styled.div`
  display: none;
  width: 0;
  height: 0;
  /* border-bottom: 20vh solid #1a1a1a; */
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.bg})`};
  height: 184px;

  @media all and (min-width: 769px) {
    display: block;
  }
`
const LeftTriangle = styled(Img)`
  position: absolute !important;
  width: 42.25vw;
  bottom: 0;
  right: 0;
  display: none;

  @media all and (min-width: 769px) {
    display: block;
  }
`
const RightTriangle = styled(Img)`
  position: absolute !important;
  width: 42.25vw;
  bottom: 0;
  left: 0;
  display: none;

  @media all and (min-width: 769px) {
    display: block;
  }
`
const MiddleTriangle = styled.div`
  display: none;
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 40px solid #1a1a1a;
  bottom: 0;
  left: calc(50% - 35px);
  right: calc(50% - 35px);
  position: absolute;

  @media all and (min-width: 769px) {
    display: block;
  }
`
const GuyContainer = styled.div`
  position: absolute;
  display: none;

  @media all and (min-width: 768px) {
    display: block;
    width: 500px;
    left: -20vw;
    bottom: 15vh;
  }

  @media all and (min-width: 769px) {
    width: 400px;
    left: 1vw;
    bottom: 4vh;
  }

  @media all and (min-width: 1025px) {
    width: 450px;
    left: 6vw;
    bottom: 5vh;
  }

  /* @media all and (min-width: 1440px) {
    left: 6vw;
    bottom: 5vh;
    width: 0px;
  } */

  @media all and (min-width: 1600px) {
    width: 690px;
    left: 8vw;
  }
`
const GirlContainer = styled.div`
  position: absolute;
  bottom: 2.85vh;
  width: 200px;

  @media all and (min-width: 768px) {
    width: 300px;
    display: block;
    right: 3vw;
  }

  @media all and (min-width: 769px) {
    width: 380px;
  }

  @media all and (min-width: 1025px) {
    width: 450px;
  }

  /* @media all and (min-width: 1440px) {
    width: 550px;
  } */

  @media all and (min-width: 1600px) {
    width: 690px;
  }
`
const ArrowContainer = styled.div`
  display: none;
  width: 16px;
  position: absolute;
  bottom: 20px;
  transform: translate(50%, 0);
  right: 50%;

  @media all and (min-width: 1281px) {
    bottom: 75px;
    display: block;
  }

  @media all and (min-width: 1600px) {
    bottom: 125px;
    display: block;
  }
`

const Hero = ({ homepage, title, bg, showTriangles = true }) => {
  const { hero, rightPoligon, leftPoligon, guy, girl, arrow } = useHeroAssets()
  return (
    <Section bg={homepage ? hero : bg} homepage={homepage}>
      <Container homepage={homepage}>
        <HeroTitle className="wow fadeIn" homepage={homepage}>
          {homepage ? (
            <>
              Szó bennszakad,
              <br /> hang fennakad
              <br />
              <Accent>lehellet megszegik...</Accent>
            </>
          ) : (
            title
          )}
        </HeroTitle>
        {homepage && (
          <StyledButton className="wow fadeIn" to="#rolunk">
            Legyél részese
          </StyledButton>
        )}
        {showTriangles ? <LeftTriangle fluid={leftPoligon} alt="left-triangle" />: null}
        <MiddleTriangle />
        {showTriangles ? <RightTriangle fluid={rightPoligon} alt="right-triangle" />: null}
        {homepage && (
          <ArrowContainer className="wow fadeIn">
            <Img fluid={arrow} alt="down-arrow" />
          </ArrowContainer>
        )}
      </Container>
      {homepage && (
        <>
          <GuyContainer className="wow fadeInLeft">
            <Img fluid={guy} alt="flip-unit-beugrás" />
          </GuyContainer>
          <GirlContainer className="wow fadeInRight">
            <Img fluid={girl} alt="flip-unit-szaltó" />
          </GirlContainer>
        </>
      )}
    </Section>
  )
}

export default Hero
