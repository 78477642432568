import React, { useState, useEffect } from "react"
import { useSwipeable } from "react-swipeable"
import styled from "styled-components"

import Container from "../Container"
import LeftArrow from "../../images/flipunit__icon__left-arrow__white.svg"
import RightArrow from "../../images/flipunit__icon__right-arrow__white.svg"
import EmptyTriangle from "../../images/flipunit__icon__bullet-empty__white.svg"
import FilledTriangle from "../../images/flipunit__icon__bullet__white.svg"
import { useRecommendations } from "../../hooks/recommendations"
import RecommendationCard from "../RecommendationCard"

const Section = styled.section`
  padding: 80px 0 70px 0;
  background-color: #1a1a1a;
`
const SectionTitle = styled.h2`
  font-size: 2.22222223em;
  text-align: center;
  margin-bottom: 5rem;
  line-height: 0.9;
`
const Accent = styled.span`
  color: #6c9fff;
`
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 15px;
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
const CardContainer = styled.div`
  display: flex;
  margin: 0 -15px;
  overflow: hidden;
  cursor: pointer;
`
const StyledEmptyTriangle = styled(EmptyTriangle)``
const StyledFilledTriangle = styled(FilledTriangle)``
const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  & ${StyledEmptyTriangle} {
    ${props => (props.active ? "transform: rotate(180deg)" : "")};
    transition: transform 0.5s ease;
    will-change: transform;
  }
`

const Recommendation = () => {
  const recommendations = useRecommendations()
  const [visibleId, setVisibleId] = useState(0)
  const maxLength = recommendations.length

  useEffect(() => {
    const interval = setInterval(() => next(), 25000)
    return function cleanup() {
      clearInterval(interval)
    }
  })

  const prev = () => {
    if (visibleId - 1 < 0) {
      setVisibleId(maxLength - 1)
    } else {
      setVisibleId(visibleId - 1)
    }
  }
  const next = () => {
    if (visibleId + 1 >= maxLength) {
      setVisibleId(0)
    } else {
      setVisibleId(visibleId + 1)
    }
  }
  const goTo = id => () => setVisibleId(id)
  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    trackTouch: true,
    trackMouse: true,
  })
  return (
    <Section>
      <Container>
        <SectionTitle className="wow fadeIn">
          <Accent>Ügyfeleink</Accent> mondták
        </SectionTitle>
        <CardContainer {...handlers}>
          {recommendations.map((recommendation, idx) => (
            <RecommendationCard
              position={visibleId * -100}
              key={idx}
              {...recommendation}
            />
          ))}
        </CardContainer>
        <Pagination className="wow fadeIn">
          <IconContainer onClick={prev}>
            <LeftArrow />
          </IconContainer>
          {recommendations.map((r, idx) => (
            <IconContainer
              key={idx}
              onClick={goTo(idx)}
              active={idx === visibleId}
            >
              <StyledEmptyTriangle />
            </IconContainer>
          ))}
          <IconContainer onClick={next}>
            <RightArrow />
          </IconContainer>
        </Pagination>
      </Container>
    </Section>
  )
}

export default Recommendation
