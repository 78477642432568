import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { useFeaturedPosts } from "../../hooks/featuredPosts"
import Button from "../Button"
import Container from "../Container"

const Section = styled.section`
  position: relative;
  padding-bottom: 2.2rem;
`
const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 45px;
  grid-column-gap: 15px;
  margin-bottom: 50px;

  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media all and (min-width: 769px) {
    grid-gap: 45px;
  }
`
const Post = styled.div``
const StyledLink = styled(Link)`
  text-decoration: none;
`
const PostTitle = styled.h3`
  color: #6c9fff;
  font-size: 1.66666667em;
  margin-bottom: 0;
`
const PostImg = styled.div`
  max-width: 560px;
  height: 250px;
  background-color: transparent;
  margin-bottom: 1.5rem;
  position: relative;
  background-image: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%
  );
  transition: all 0.3s ease-in-out;

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 2px,
      2px 2px,
      2px calc(100% - 2px),
      calc(100% - 11px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 11px),
      calc(100% - 2px) 2px,
      0 2px
    );
    background: #fff;
    z-index: -1;
    opacity: 1;
  }
`
const PostExcerpt = styled.p`
  margin-bottom: 1.5rem;
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const PublishedDateRow = styled.div`
  margin-bottom: 1.45rem;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
`
const PublishedText = styled.span`
  margin-right: 10px;
`
const PublishedDate = styled.span``
const SectionTitle = styled.h2`
  font-size: 2.22222223em;
  text-align: center;
  margin-bottom: 4.16666667rem;
  line-height: 0.9;
  color: #fff;
`
const Accent = styled.span`
  color: #6c9fff;
`

const Blog = () => {
  const featuredPosts = useFeaturedPosts()
  return (
    <Section id="rolunk">
      <Container>
        <SectionTitle className="wow fadeIn">
          <Accent>Pillantson be a</Accent> kulisszák mögé!
        </SectionTitle>
        <BlogGrid>
          {featuredPosts.map(
            ({ slug, title, cover, excerpt, publishDate }, idx) => (
              <Post
                key={idx}
                className="wow fadeIn"
                data-wow-delay={`${(idx * 2) / 10}`}
                itemScope
                itemType="http://schema.org/Article"
              >
                <StyledLink to={slug}>
                  <PostTitle itemProp="name">{title}</PostTitle>
                </StyledLink>
                <PublishedDateRow
                  itemProp="datePublished"
                  dateTime={new Date(publishDate).toISOString().split("T")[0]}
                >
                  <PublishedText>Publikálás dátuma:</PublishedText>
                  <PublishedDate>
                    {new Date(publishDate).toISOString().split("T")[0]}
                  </PublishedDate>
                </PublishedDateRow>
                <StyledLink to={slug}>
                  <PostImg bg={cover.src.fluid.src} />
                </StyledLink>
                <PostExcerpt>{excerpt}</PostExcerpt>
                <StyledButtonContainer>
                  <Button to={slug}>Tovább</Button>
                </StyledButtonContainer>
              </Post>
            )
          )}
        </BlogGrid>
      </Container>
    </Section>
  )
}

export default Blog
