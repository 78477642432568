import React from "react"
import styled from "styled-components"

import { useCTAAssets } from "../../hooks/cta"
import Button from "../Button"

const Section = styled.section`
  padding: 130px 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.bg})`};
  text-align: center;

  @media all and (min-width: 768px) {
    padding: 230px 15px;
  }
`
const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
`
const CTATitle = styled.h2`
  font-size: 3em;
  color: #6c9fff;
  background-color: #1b2840;
  padding: 20px 0 10px 0;
  line-height: 0.9;

  @media all and (min-width: 768px) {
    font-size: 5em;
    padding: 20px 0 0 0;
  }
`
const AltTitle = styled.p`
  font-size: 1em;

  @media all and (min-width: 768px) {
    font-size: 1.33333333em;
  }
`
const Accent = styled.span`
  font-weight: 700;
`
const AltText = styled.p`
  color: #1b2840;
  font-style: italic;
  margin-bottom: 2rem;
`
const StyledButton = styled(Button)`
  font-size: 1.5em;
  max-width: 230px;
  width: 100%;
  padding: 10px 65px 5px 65px;
  letter-spacing: 2px;
`

const CTA = () => {
  const { bg } = useCTAAssets()
  return (
    <Section bg={bg}>
      <Container>
        <CTATitle className="wow fadeInRight">
          Kérd teljes árlistánkat!
        </CTATitle>
        <AltTitle className="wow fadeInRight" data-wow-delay=".2s">
          Rendezvényedre szabott ajánlatunkat garantáltan{" "}
          <Accent>24 órán belül</Accent> megküldjük.
        </AltTitle>
        <AltText className="wow fadeInRight" data-wow-delay=".4s">
          Ügynökségünk várja megkeresésed.
        </AltText>
        <StyledButton
          className="wow fadeInRight"
          data-wow-delay=".6s"
          to="/ajanlatkeres"
        >
          Tovább
        </StyledButton>
      </Container>
    </Section>
  )
}

export default CTA
