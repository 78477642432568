import { useStaticQuery, graphql } from "gatsby"

export const useFeaturedPosts = () => {
  const collection = useStaticQuery(
    graphql`
      query postsAndHighlightedPosts {
        posts: allMdx(
          filter: {
            fileAbsolutePath: { regex: "/posts/" }
            frontmatter: { featured: { eq: true } }
          }
          sort: { fields: frontmatter___publishDate, order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                slug
                title
                cover {
                  src: childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                publishDate
                excerpt
              }
            }
          }
        }
      }
    `
  )

  return collection.posts.edges.reduce(
    (acc, post) => [
      ...acc,
      {
        id: post.node.id,
        ...(post.node ? { ...post.node.frontmatter } : {}),
      },
    ],
    []
  )
}
