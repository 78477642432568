import React from "react"
import styled, { keyframes } from "styled-components"
import Img from "gatsby-image"

import { useHeroAssets } from "../../hooks/hero"

const move = keyframes`
  25% {
    opacity: 1;

  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
`

const ScrollContainer = styled.a`
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.5rem;
`
const Chevron = styled.span`
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: ${move} 3s ease-out infinite;

  &:first-child {
    animation: ${move} 3s ease-out 1s infinite;
  }

  &:nth-child(2) {
    animation: ${move} 3s ease-out 2s infinite;
  }

  &:before,
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
  }

  &:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }

  &:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }
`

const Section = styled.section`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ${props => props.bg && `background-image: url(${props.bg});`};
  background-color: rgba(0, 0, 0, 0.3);
  height: ${props => (props.homepage ? "calc(100vh - 95.5px)" : "72vh")};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  ${props => (props.homepage ? "margin-top: -115px" : "")};
`
const Container = styled.div`
  max-width: 630px;
  margin: 0 auto;
  text-align: center;

  ${props => !props.homepage && "padding-bottom: 2rem"};

  @media all and (min-width: 768px) {
    ${props => !props.homepage && "padding-bottom: 3.75rem"};
  }
`
const HeroTitle = styled.h1`
  font-size: 3em;
  line-height: 0.9;
  color: #fff;
  margin: 0;
  /* padding-bottom: 2rem; */
  letter-spacing: 0;

  @media all and (min-width: 768px) {
    font-size: 4em;
    /* padding-bottom: 3.75rem; */
  }
`
const Triangle = styled.div`
  display: none;
  width: 0;
  height: 0;
  border-bottom: 20vh solid #1a1a1a;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.bg})`};

  @media all and (min-width: 769px) {
    display: block;
  }
`
const LeftTriangle = styled(Img)`
  position: absolute !important;
  width: 42.25vw;
  bottom: 0;
  right: 0;
  display: none;

  @media all and (min-width: 769px) {
    display: block;
  }
`
const RightTriangle = styled(Img)`
  position: absolute !important;
  width: 42.25vw;
  bottom: 0;
  left: 0;
  display: none;

  @media all and (min-width: 769px) {
    display: block;
  }
`
const MiddleTriangle = styled.div`
  display: none;
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 40px solid #1a1a1a;
  bottom: 0;
  left: calc(50% - 35px);
  right: calc(50% - 35px);
  position: absolute;

  @media all and (min-width: 769px) {
    display: block;
  }
`
const VideoBackground = styled.div`
  height: inherit;
  width: 100%;
  position: absolute;
  z-index: -100;
`
const Video = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`

const VideoHero = ({ homepage, video, bg, title, htmlTitle, target }) => {
  const { rightPoligon, leftPoligon } = useHeroAssets()
  return (
    <Section homepage={homepage}>
      <Container homepage={homepage}>
        {htmlTitle ? (
          <HeroTitle
            className="wow fadeIn"
            dangerouslySetInnerHTML={{ __html: htmlTitle }}
          />
        ) : (
          <HeroTitle className="wow fadeIn">{title}</HeroTitle>
        )}
        <LeftTriangle fluid={leftPoligon} alt="left-triangle" />
        {homepage ? (
          <ScrollContainer href={target}>
            <Chevron />
            <Chevron />
            <Chevron />
          </ScrollContainer>
        ) : (
          <MiddleTriangle />
        )}
        <RightTriangle fluid={rightPoligon} alt="right-triangle" />
      </Container>
      <VideoBackground>
        <Video autoPlay muted loop playsInline poster={bg} preload="metadata">
          <source src={video} type="video/mp4" />
        </Video>
      </VideoBackground>
    </Section>
  )
}

export default VideoHero
