import { useStaticQuery, graphql } from "gatsby"

export const useCTAAssets = () => {
  const images = useStaticQuery(
    graphql`
      query CTAImages {
        bg: file(relativePath: { eq: "bg__CTA2@2x-80.jpg" }) {
          src: childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              src
            }
          }
        }
      }
    `
  )

  return {
    bg: images.bg.src.fluid.src,
  }
}
