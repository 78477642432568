import { useStaticQuery, graphql } from "gatsby"

export const useRecommendations = () => {
  const collection = useStaticQuery(
    graphql`
      query recommendations {
        recommendations: allMdx(
          filter: { fileAbsolutePath: { regex: "/recommendations/" } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              body
              frontmatter {
                name
                title
                cover {
                  src: childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                profile {
                  src: childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                order
              }
            }
          }
        }
      }
    `
  )

  return collection.recommendations.edges.reduce(
    (acc, recommendation) => [
      ...acc,
      {
        body: recommendation.node.body,
        ...(recommendation.node ? { ...recommendation.node.frontmatter } : {}),
      },
    ],
    []
  )
}
